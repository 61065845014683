exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-art-js": () => import("./../../../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-js": () => import("./../../../src/pages/life.js" /* webpackChunkName: "component---src-pages-life-js" */),
  "component---src-pages-literature-js": () => import("./../../../src/pages/literature.js" /* webpackChunkName: "component---src-pages-literature-js" */),
  "component---src-pages-public-welfare-js": () => import("./../../../src/pages/public-welfare.js" /* webpackChunkName: "component---src-pages-public-welfare-js" */),
  "component---src-pages-society-js": () => import("./../../../src/pages/society.js" /* webpackChunkName: "component---src-pages-society-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

